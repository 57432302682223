import React from "react"
import Layout from "../components/layout"
import NavBar from "../components/navBar"
import Seo from "../components/seo"
import Container from "react-bootstrap/Container"

// * Make it center and evenly distributed
const NotFoundPage = ({ location }) => (
  <Layout>
    <Seo title="404: Page Not found" />
    <NavBar location={location} />
    <Container fluid className="text-center p-4">
      <div className="error-404">
        <h1>404</h1>
        <h2>Page Not Found</h2>
        <a href="/" className="error-404__link">
          Return to home
        </a>
      </div>
    </Container>
  </Layout>
)

export default NotFoundPage
